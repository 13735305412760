var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ready
    ? _c(
        "CRow",
        { staticClass: "basic-charge-part" },
        [
          _c(
            "CCol",
            { attrs: { md: "8" } },
            [
              _c(
                "ValidatedFormCard",
                {
                  ref: "validatedFormCard",
                  attrs: {
                    titleTranslationKey:
                      "components.playroomEditParts.BasicChargePart.title",
                    ready: _vm.ready,
                    model: _vm.$v,
                    onSave: _vm.save
                  },
                  on: {
                    "part-save-status-type-change": function(status) {
                      return _vm.$emit("part-save-status-type-change", status)
                    }
                  }
                },
                [
                  _c(
                    "CRow",
                    [
                      _c(
                        "CCol",
                        { attrs: { md: "6" } },
                        [
                          _c(
                            "CCard",
                            [
                              _c("CCardHeader", {
                                directives: [
                                  {
                                    name: "t",
                                    rawName: "v-t",
                                    value:
                                      "components.playroomEditParts.BasicChargePart.overnightBooking",
                                    expression:
                                      "'components.playroomEditParts.BasicChargePart.overnightBooking'"
                                  }
                                ]
                              }),
                              _c(
                                "CCardBody",
                                [
                                  _c("ValidatedInput", {
                                    attrs: {
                                      translationKey: "playroom.baseRent",
                                      field:
                                        _vm.$v.playroom.baseRentForOvernight,
                                      value:
                                        _vm.$v.playroom.baseRentForOvernight
                                          .$model,
                                      "data-cy": "basic-rent",
                                      type: "number",
                                      disabled: !_vm.playroom.allowOvernightStay
                                    },
                                    on: {
                                      "update:value": function($event) {
                                        return _vm.$set(
                                          _vm.$v.playroom.baseRentForOvernight,
                                          "$model",
                                          $event
                                        )
                                      },
                                      focusChange: _vm.onFocusChange
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "append-content",
                                          fn: function() {
                                            return [
                                              _c("CIcon", {
                                                attrs: { name: "cis-euro" }
                                              }),
                                              _vm._v(" "),
                                              _c("span", {
                                                directives: [
                                                  {
                                                    name: "t",
                                                    rawName: "v-t",
                                                    value:
                                                      "components.playroomEditParts.BasicChargePart.perBooking",
                                                    expression:
                                                      "'components.playroomEditParts.BasicChargePart.perBooking'"
                                                  }
                                                ]
                                              })
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      58759646
                                    )
                                  }),
                                  _c("ValidatedCheckbox", {
                                    attrs: {
                                      field:
                                        _vm.$v.playroom
                                          .allowSurchargePerPersonPerBookingForOvernight,
                                      checked:
                                        _vm.$v.playroom
                                          .allowSurchargePerPersonPerBookingForOvernight
                                          .$model,
                                      translationKey:
                                        "playroom.allowSurchargePerPersonPerBookingForOvernight",
                                      disabled: !_vm.enableAllowSurchargePerPersonPerBookingForOvernight
                                    },
                                    on: {
                                      "update:checked": function($event) {
                                        return _vm.$set(
                                          _vm.$v.playroom
                                            .allowSurchargePerPersonPerBookingForOvernight,
                                          "$model",
                                          $event
                                        )
                                      }
                                    }
                                  }),
                                  _vm.playroom
                                    .allowSurchargePerPersonPerBookingForOvernight
                                    ? _c("ValidatedInput", {
                                        attrs: {
                                          field:
                                            _vm.$v.playroom
                                              .surchargePerPersonPerBookingForOvernight,
                                          value:
                                            _vm.$v.playroom
                                              .surchargePerPersonPerBookingForOvernight
                                              .$model,
                                          "data-cy":
                                            "surchargePerPersonPerBookingForOvernight",
                                          type: "number",
                                          translationKey:
                                            "playroom.surchargePerPersonPerBookingForOvernight"
                                        },
                                        on: {
                                          "update:value": function($event) {
                                            return _vm.$set(
                                              _vm.$v.playroom
                                                .surchargePerPersonPerBookingForOvernight,
                                              "$model",
                                              $event
                                            )
                                          },
                                          focusChange: _vm.onFocusChange
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "append-content",
                                              fn: function() {
                                                return [
                                                  _c("CIcon", {
                                                    attrs: { name: "cis-euro" }
                                                  }),
                                                  _vm._v(" "),
                                                  _c("span", {
                                                    directives: [
                                                      {
                                                        name: "t",
                                                        rawName: "v-t",
                                                        value:
                                                          "components.playroomEditParts.BasicChargePart.perBooking",
                                                        expression:
                                                          "'components.playroomEditParts.BasicChargePart.perBooking'"
                                                      }
                                                    ]
                                                  })
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ],
                                          null,
                                          false,
                                          58759646
                                        )
                                      })
                                    : _vm._e(),
                                  _c("ValidatedCheckbox", {
                                    attrs: {
                                      field:
                                        _vm.$v.playroom
                                          .allowSurchargePerPersonPerNight,
                                      checked:
                                        _vm.$v.playroom
                                          .allowSurchargePerPersonPerNight
                                          .$model,
                                      translationKey:
                                        "playroom.allowSurchargePerPersonPerNight",
                                      disabled: !_vm.enableAllowSurchargePerPersonPerNight
                                    },
                                    on: {
                                      "update:checked": function($event) {
                                        return _vm.$set(
                                          _vm.$v.playroom
                                            .allowSurchargePerPersonPerNight,
                                          "$model",
                                          $event
                                        )
                                      }
                                    }
                                  }),
                                  _vm.playroom.allowSurchargePerPersonPerNight
                                    ? _c("ValidatedInput", {
                                        attrs: {
                                          field:
                                            _vm.$v.playroom
                                              .surchargePerPersonPerNight,
                                          value:
                                            _vm.$v.playroom
                                              .surchargePerPersonPerNight
                                              .$model,
                                          "data-cy":
                                            "surchargePerPersonPerNight",
                                          type: "number",
                                          translationKey:
                                            "playroom.surchargePerPersonPerNight",
                                          disabled: !_vm.playroom
                                            .allowOvernightStay
                                        },
                                        on: {
                                          "update:value": function($event) {
                                            return _vm.$set(
                                              _vm.$v.playroom
                                                .surchargePerPersonPerNight,
                                              "$model",
                                              $event
                                            )
                                          },
                                          focusChange: _vm.onFocusChange
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "append-content",
                                              fn: function() {
                                                return [
                                                  _c("CIcon", {
                                                    attrs: { name: "cis-euro" }
                                                  }),
                                                  _vm._v(" "),
                                                  _c("span", {
                                                    directives: [
                                                      {
                                                        name: "t",
                                                        rawName: "v-t",
                                                        value:
                                                          "components.playroomEditParts.BasicChargePart.perNight",
                                                        expression:
                                                          "'components.playroomEditParts.BasicChargePart.perNight'"
                                                      }
                                                    ]
                                                  })
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ],
                                          null,
                                          false,
                                          2503520766
                                        )
                                      })
                                    : _vm._e(),
                                  _c("ValidatedCheckbox", {
                                    attrs: {
                                      field: _vm.$v.playroom.showBedTax,
                                      checked: _vm.showBedTax,
                                      translationKey: "playroom.showBedTax",
                                      disabled: !_vm.playroom.allowOvernightStay
                                    },
                                    on: {
                                      "update:checked": function($event) {
                                        _vm.showBedTax = $event
                                      }
                                    }
                                  }),
                                  _vm.showBedTax
                                    ? _c("ValidatedSelect", {
                                        attrs: {
                                          field: _vm.$v.playroom.bedTaxType,
                                          options: _vm.bedTaxTypeOptions,
                                          value:
                                            _vm.$v.playroom.bedTaxType.$model,
                                          translationKey: "playroom.bedTaxType",
                                          disabled: !_vm.playroom
                                            .allowOvernightStay
                                        },
                                        on: {
                                          "update:value": function($event) {
                                            return _vm.$set(
                                              _vm.$v.playroom.bedTaxType,
                                              "$model",
                                              $event
                                            )
                                          },
                                          focusChange: _vm.onFocusChange
                                        }
                                      })
                                    : _vm._e(),
                                  _vm.showBedTax &&
                                  _vm.playroom.bedTaxType !== "NoBedTax" &&
                                  _vm.playroom.bedTaxType !== "Unknown"
                                    ? _c("ValidatedInput", {
                                        attrs: {
                                          field: _vm.$v.playroom.bedTax,
                                          value: _vm.$v.playroom.bedTax.$model,
                                          "data-cy": "bedTax",
                                          type: "number",
                                          translationKey: "playroom.bedTax",
                                          disabled: !_vm.playroom
                                            .allowOvernightStay
                                        },
                                        on: {
                                          "update:value": function($event) {
                                            return _vm.$set(
                                              _vm.$v.playroom.bedTax,
                                              "$model",
                                              $event
                                            )
                                          },
                                          focusChange: _vm.onFocusChange
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "append-content",
                                              fn: function() {
                                                return [
                                                  _vm._v(
                                                    _vm._s(_vm.bedTaxAppendText)
                                                  )
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ],
                                          null,
                                          false,
                                          2858475815
                                        )
                                      })
                                    : _vm._e(),
                                  _c("ValidatedInput", {
                                    attrs: {
                                      field:
                                        _vm.$v.playroom.reducedTaxPercentage,
                                      value:
                                        _vm.$v.playroom.reducedTaxPercentage
                                          .$model,
                                      "data-cy": "reducedTaxPercentage",
                                      type: "number",
                                      translationKey:
                                        "playroom.reducedTaxPercentage",
                                      disabled: !_vm.playroom.allowOvernightStay
                                    },
                                    on: {
                                      "update:value": function($event) {
                                        return _vm.$set(
                                          _vm.$v.playroom.reducedTaxPercentage,
                                          "$model",
                                          $event
                                        )
                                      },
                                      focusChange: _vm.onFocusChange
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "append-content",
                                          fn: function() {
                                            return [_vm._v("%")]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      814457650
                                    )
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "CCol",
                        { attrs: { md: "6" } },
                        [
                          _c(
                            "CCard",
                            [
                              _c("CCardHeader", {
                                directives: [
                                  {
                                    name: "t",
                                    rawName: "v-t",
                                    value:
                                      "components.playroomEditParts.BasicChargePart.hourlyBooking",
                                    expression:
                                      "'components.playroomEditParts.BasicChargePart.hourlyBooking'"
                                  }
                                ]
                              }),
                              _c(
                                "CCardBody",
                                [
                                  _c("ValidatedInput", {
                                    attrs: {
                                      translationKey: "playroom.baseRent",
                                      field: _vm.$v.playroom.baseRentForHourly,
                                      value:
                                        _vm.$v.playroom.baseRentForHourly
                                          .$model,
                                      "data-cy": "basic-rent",
                                      type: "number",
                                      disabled: !_vm.playroom.allowHourBooking
                                    },
                                    on: {
                                      "update:value": function($event) {
                                        return _vm.$set(
                                          _vm.$v.playroom.baseRentForHourly,
                                          "$model",
                                          $event
                                        )
                                      },
                                      focusChange: _vm.onFocusChange
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "append-content",
                                          fn: function() {
                                            return [
                                              _c("CIcon", {
                                                attrs: { name: "cis-euro" }
                                              }),
                                              _vm._v(" "),
                                              _c("span", {
                                                directives: [
                                                  {
                                                    name: "t",
                                                    rawName: "v-t",
                                                    value:
                                                      "components.playroomEditParts.BasicChargePart.perBooking",
                                                    expression:
                                                      "'components.playroomEditParts.BasicChargePart.perBooking'"
                                                  }
                                                ]
                                              })
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      58759646
                                    )
                                  }),
                                  _c("ValidatedCheckbox", {
                                    attrs: {
                                      field:
                                        _vm.$v.playroom
                                          .allowSurchargePerPersonPerBookingForHourly,
                                      checked:
                                        _vm.$v.playroom
                                          .allowSurchargePerPersonPerBookingForHourly
                                          .$model,
                                      translationKey:
                                        "playroom.allowSurchargePerPersonPerBookingForHourly",
                                      disabled: !_vm.enableAllowSurchargePerPersonPerBookingForHourly
                                    },
                                    on: {
                                      "update:checked": function($event) {
                                        return _vm.$set(
                                          _vm.$v.playroom
                                            .allowSurchargePerPersonPerBookingForHourly,
                                          "$model",
                                          $event
                                        )
                                      }
                                    }
                                  }),
                                  _vm.playroom
                                    .allowSurchargePerPersonPerBookingForHourly
                                    ? _c("ValidatedInput", {
                                        attrs: {
                                          field:
                                            _vm.$v.playroom
                                              .surchargePerPersonPerBookingForHourly,
                                          value:
                                            _vm.$v.playroom
                                              .surchargePerPersonPerBookingForHourly
                                              .$model,
                                          "data-cy":
                                            "surchargePerPersonPerBookingForHourly",
                                          translationKey:
                                            "playroom.surchargePerPersonPerBookingForHourly",
                                          type: "number",
                                          disabled: !_vm.playroom
                                            .allowHourBooking
                                        },
                                        on: {
                                          "update:value": function($event) {
                                            return _vm.$set(
                                              _vm.$v.playroom
                                                .surchargePerPersonPerBookingForHourly,
                                              "$model",
                                              $event
                                            )
                                          },
                                          focusChange: _vm.onFocusChange
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "append-content",
                                              fn: function() {
                                                return [
                                                  _c("CIcon", {
                                                    attrs: { name: "cis-euro" }
                                                  }),
                                                  _vm._v(" "),
                                                  _c("span", {
                                                    directives: [
                                                      {
                                                        name: "t",
                                                        rawName: "v-t",
                                                        value:
                                                          "components.playroomEditParts.BasicChargePart.perBooking",
                                                        expression:
                                                          "'components.playroomEditParts.BasicChargePart.perBooking'"
                                                      }
                                                    ]
                                                  })
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ],
                                          null,
                                          false,
                                          58759646
                                        )
                                      })
                                    : _vm._e(),
                                  _c("ValidatedCheckbox", {
                                    attrs: {
                                      field:
                                        _vm.$v.playroom
                                          .allowSurchargePerPersonPerHour,
                                      checked:
                                        _vm.$v.playroom
                                          .allowSurchargePerPersonPerHour
                                          .$model,
                                      translationKey:
                                        "playroom.allowSurchargePerPersonPerHour",
                                      disabled: !_vm.enableAllowSurchargePerPersonPerHour
                                    },
                                    on: {
                                      "update:checked": function($event) {
                                        return _vm.$set(
                                          _vm.$v.playroom
                                            .allowSurchargePerPersonPerHour,
                                          "$model",
                                          $event
                                        )
                                      }
                                    }
                                  }),
                                  _vm.playroom.allowSurchargePerPersonPerHour
                                    ? _c("ValidatedInput", {
                                        attrs: {
                                          field:
                                            _vm.$v.playroom
                                              .surchargePerPersonPerHour,
                                          value:
                                            _vm.$v.playroom
                                              .surchargePerPersonPerHour.$model,
                                          "data-cy":
                                            "surchargePerPersonPerHour",
                                          translationKey:
                                            "playroom.surchargePerPersonPerHour",
                                          type: "number",
                                          disabled: !_vm.playroom
                                            .allowHourBooking
                                        },
                                        on: {
                                          "update:value": function($event) {
                                            return _vm.$set(
                                              _vm.$v.playroom
                                                .surchargePerPersonPerHour,
                                              "$model",
                                              $event
                                            )
                                          },
                                          focusChange: _vm.onFocusChange
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "append-content",
                                              fn: function() {
                                                return [
                                                  _c("CIcon", {
                                                    attrs: { name: "cis-euro" }
                                                  }),
                                                  _vm._v(" "),
                                                  _c("span", {
                                                    directives: [
                                                      {
                                                        name: "t",
                                                        rawName: "v-t",
                                                        value:
                                                          "components.playroomEditParts.BasicChargePart.perHour",
                                                        expression:
                                                          "'components.playroomEditParts.BasicChargePart.perHour'"
                                                      }
                                                    ]
                                                  })
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ],
                                          null,
                                          false,
                                          1098865214
                                        )
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "CCol",
            { attrs: { md: "4" } },
            [
              _c("HelpCard", {
                attrs: {
                  focusFor: _vm.focusFor,
                  name: "components.playroomEditParts.BasicChargePart.info.box1"
                }
              })
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }