var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CRow",
    { staticClass: "playroom-addition-part" },
    [
      _c(
        "CCol",
        { attrs: { md: "8" } },
        [
          _c(
            "ValidatedFormCard",
            {
              ref: "validatedFormCard",
              attrs: {
                titleTranslationKey:
                  "components.playroomEditParts.PlayroomAdditionPart.title",
                titleTooltipTranslationKey:
                  "components.playroomEditParts.PlayroomAdditionPart.tooltip",
                ready: _vm.ready,
                model: _vm.$v,
                onSave: _vm.save
              },
              on: {
                "part-save-status-type-change": function(status) {
                  return _vm.$emit("part-save-status-type-change", status)
                }
              }
            },
            [
              _vm.ready
                ? _c(
                    "div",
                    [
                      _c("ErrorMessage", {
                        attrs: { message: _vm.errorMessage }
                      }),
                      _c(
                        "draggable",
                        {
                          attrs: { handle: ".drag-handle" },
                          on: {
                            start: function($event) {
                              _vm.drag = true
                            },
                            end: function($event) {
                              _vm.drag = false
                            },
                            change: _vm.onDragChange
                          },
                          model: {
                            value: _vm.playroom.additions,
                            callback: function($$v) {
                              _vm.$set(_vm.playroom, "additions", $$v)
                            },
                            expression: "playroom.additions"
                          }
                        },
                        [
                          _vm._l(
                            _vm.$v.playroom.additions.$each.$iter,
                            function(playroomAddition, index) {
                              return _c(
                                "CCard",
                                { key: playroomAddition.$model.id + index },
                                [
                                  _c(
                                    "CCardHeader",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.collapseClick(index)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "dragable drag-handle" },
                                        [
                                          _c("CIcon", {
                                            attrs: {
                                              name: "cis-hamburger-menu"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            playroomAddition.$model.name ||
                                              _vm.$t(
                                                "components.PlayroomAdditionEditForm.newOption"
                                              )
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "CCollapse",
                                    {
                                      attrs: {
                                        show:
                                          _vm.activeCollapsePlayroomIndex ==
                                          index
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "addition-part" },
                                        [
                                          _c(
                                            "CCardBody",
                                            [
                                              _c(
                                                "CRow",
                                                [
                                                  _vm.errorMessage
                                                    ? _c(
                                                        "CCol",
                                                        { attrs: { md: "12" } },
                                                        [
                                                          _c("ErrorMessage", {
                                                            attrs: {
                                                              message:
                                                                _vm.errorMessage
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _c(
                                                    "CCol",
                                                    { attrs: { md: "12" } },
                                                    [
                                                      _c("ValidatedInput", {
                                                        attrs: {
                                                          translationKey:
                                                            "playroomAddition.name",
                                                          field:
                                                            playroomAddition.name,
                                                          value:
                                                            playroomAddition
                                                              .name.$model
                                                        },
                                                        on: {
                                                          "update:value": function(
                                                            $event
                                                          ) {
                                                            return _vm.$set(
                                                              playroomAddition.name,
                                                              "$model",
                                                              $event
                                                            )
                                                          }
                                                        }
                                                      }),
                                                      _c("ValidatedTextArea", {
                                                        attrs: {
                                                          translationKey:
                                                            "playroomAddition.description",
                                                          field:
                                                            playroomAddition.description,
                                                          value:
                                                            playroomAddition
                                                              .description
                                                              .$model
                                                        },
                                                        on: {
                                                          "update:value": function(
                                                            $event
                                                          ) {
                                                            return _vm.$set(
                                                              playroomAddition.description,
                                                              "$model",
                                                              $event
                                                            )
                                                          }
                                                        }
                                                      }),
                                                      _c("ValidatedSelect", {
                                                        attrs: {
                                                          options:
                                                            _vm.additionTypeOptions,
                                                          field:
                                                            playroomAddition.forBookingType,
                                                          value:
                                                            playroomAddition
                                                              .forBookingType
                                                              .$model,
                                                          translationKey:
                                                            "playroomAddition.forBookingType"
                                                        },
                                                        on: {
                                                          "update:value": function(
                                                            $event
                                                          ) {
                                                            return _vm.$set(
                                                              playroomAddition.forBookingType,
                                                              "$model",
                                                              $event
                                                            )
                                                          }
                                                        }
                                                      }),
                                                      _vm.showBookingTypeConflictError(
                                                        playroomAddition.$model
                                                      )
                                                        ? _c("CAlert", {
                                                            directives: [
                                                              {
                                                                name: "t",
                                                                rawName: "v-t",
                                                                value:
                                                                  "components.playroomEditParts.PlayroomAdditionPart.bookingTypeConflictError",
                                                                expression:
                                                                  "'components.playroomEditParts.PlayroomAdditionPart.bookingTypeConflictError'"
                                                              }
                                                            ],
                                                            staticClass:
                                                              "booking-type-conflict-error",
                                                            attrs: {
                                                              color: "warning"
                                                            }
                                                          })
                                                        : _vm._e(),
                                                      _c("ValidatedSwitch", {
                                                        attrs: {
                                                          field:
                                                            playroomAddition.isPricePerPiece,
                                                          checked:
                                                            playroomAddition
                                                              .isPricePerPiece
                                                              .$model,
                                                          translationKey:
                                                            "playroomAddition.isPricePerPiece"
                                                        },
                                                        on: {
                                                          "update:checked": function(
                                                            $event
                                                          ) {
                                                            return _vm.$set(
                                                              playroomAddition.isPricePerPiece,
                                                              "$model",
                                                              $event
                                                            )
                                                          }
                                                        }
                                                      }),
                                                      _c("ValidatedSwitch", {
                                                        attrs: {
                                                          field:
                                                            playroomAddition.isPricePerTime,
                                                          checked:
                                                            playroomAddition
                                                              .isPricePerTime
                                                              .$model,
                                                          translationKey:
                                                            "playroomAddition.isPricePerTime"
                                                        },
                                                        on: {
                                                          "update:checked": function(
                                                            $event
                                                          ) {
                                                            return _vm.$set(
                                                              playroomAddition.isPricePerTime,
                                                              "$model",
                                                              $event
                                                            )
                                                          }
                                                        }
                                                      }),
                                                      _c("ValidatedInput", {
                                                        attrs: {
                                                          field:
                                                            playroomAddition.priceForHourly,
                                                          value:
                                                            playroomAddition
                                                              .priceForHourly
                                                              .$model,
                                                          translationKey:
                                                            "playroomAddition.priceForHourly",
                                                          append: _vm.appendForHourly(
                                                            playroomAddition.$model
                                                          ),
                                                          disabled: _vm.disablePriceForHourlyInput(
                                                            playroomAddition.$model
                                                          ),
                                                          type: "number"
                                                        },
                                                        on: {
                                                          "update:value": function(
                                                            $event
                                                          ) {
                                                            return _vm.$set(
                                                              playroomAddition.priceForHourly,
                                                              "$model",
                                                              $event
                                                            )
                                                          }
                                                        }
                                                      }),
                                                      _c("ValidatedInput", {
                                                        attrs: {
                                                          field:
                                                            playroomAddition.priceForOvernight,
                                                          value:
                                                            playroomAddition
                                                              .priceForOvernight
                                                              .$model,
                                                          translationKey:
                                                            "playroomAddition.priceForOvernight",
                                                          append: _vm.appendForOvernight(
                                                            playroomAddition.$model
                                                          ),
                                                          disabled: _vm.disablePriceForOvernightInput(
                                                            playroomAddition.$model
                                                          ),
                                                          type: "number"
                                                        },
                                                        on: {
                                                          "update:value": function(
                                                            $event
                                                          ) {
                                                            return _vm.$set(
                                                              playroomAddition.priceForOvernight,
                                                              "$model",
                                                              $event
                                                            )
                                                          }
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "CCardFooter",
                                            [
                                              playroomAddition.$model
                                                .canBeDeleted
                                                ? _c("DeleteButton", {
                                                    attrs: {
                                                      onDelete: function() {
                                                        return _vm.onDeletedPlayroomAddition(
                                                          playroomAddition.$model,
                                                          index
                                                        )
                                                      },
                                                      type: "text"
                                                    }
                                                  })
                                                : _vm._e(),
                                              !playroomAddition.$model
                                                .canBeDeleted
                                                ? _c(
                                                    "span",
                                                    [
                                                      playroomAddition.$model
                                                        .isActive
                                                        ? _c("CButton", {
                                                            directives: [
                                                              {
                                                                name: "t",
                                                                rawName: "v-t",
                                                                value:
                                                                  "components.playroomEditParts.PlayroomAdditionPart.deactivateButton",
                                                                expression:
                                                                  "'components.playroomEditParts.PlayroomAdditionPart.deactivateButton'"
                                                              }
                                                            ],
                                                            attrs: {
                                                              color: "warning"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.deactivatePlayroomAddition(
                                                                  playroomAddition.$model
                                                                )
                                                              }
                                                            }
                                                          })
                                                        : _vm._e(),
                                                      !playroomAddition.$model
                                                        .isActive
                                                        ? _c("CButton", {
                                                            directives: [
                                                              {
                                                                name: "t",
                                                                rawName: "v-t",
                                                                value:
                                                                  "components.playroomEditParts.PlayroomAdditionPart.activateButton",
                                                                expression:
                                                                  "'components.playroomEditParts.PlayroomAdditionPart.activateButton'"
                                                              }
                                                            ],
                                                            attrs: {
                                                              color: "warning"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.activatePlayroomAddition(
                                                                  playroomAddition.$model
                                                                )
                                                              }
                                                            }
                                                          })
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            }
                          ),
                          _vm.stillAvailableCount > 0
                            ? _c(
                                "CButton",
                                {
                                  staticClass: "add-button",
                                  attrs: { color: "light" },
                                  on: { click: _vm.addNewAddition }
                                },
                                [
                                  _c("CIcon", { attrs: { name: "cilPlus" } }),
                                  _c("span", {
                                    staticClass: "add-text",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$t(
                                          "components.playroomEditParts.PlayroomAdditionPart.addMessage",
                                          {
                                            stillAvailableCount:
                                              _vm.stillAvailableCount
                                          }
                                        )
                                      )
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        2
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          )
        ],
        1
      ),
      _c(
        "CCol",
        { attrs: { md: "4" } },
        [
          _c("HelpCard", {
            attrs: {
              focusFor: _vm.focusFor,
              name:
                "components.playroomEditParts.PlayroomAdditionPart.info.box1"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }